import React, { useState } from "react";

const Newsletter = () => {
  const [data] = useState({
    title: "Formá parte de FRICAP",
    desc: "Si estas buscando trabajo, envianos tu CV a fricapsrl@gmail.com",
  });

  return (
    <section className="tf-section newsletter" id="cv">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="newsletter__body" data-aos="fade-up">
              <div className="block-text">
                <h3 className="mb-13">{data.title}</h3>
                <p className="fs-21">{data.desc}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
