import img1 from '../images/items/item-01.png'
import img2 from '../images/items/item-02.png'
import img3 from '../images/items/item-03.png'
import img4 from '../images/items/item-04.png'
import img5 from '../images/items/item-05.png'
import img6 from '../images/items/item-06.png'
import img7 from '../images/items/item-07.png'
import img8 from '../images/items/item-11.png'
import img9 from '../images/items/item-12.png'
import img10 from '../images/items/item-13.png'
import img11 from '../images/items/item-14.png'
import img12 from '../images/items/item-15.png'
import img13 from '../images/items/item-16.png'
import img14 from '../images/items/item-17.png'
import img15 from '../images/items/item-18.png'
import img16 from '../images/items/item-19.png'
import img17 from '../images/items/item-20.png'
import img18 from '../images/items/item-21.png'



const dataProductos = [
    {
        bg: 'bg-2',
        img: img1
    },
    {
        bg: 'bg-1',
        img: img2
    },
    {
        bg: 'bg-2',
        img: img3
    },
    {
        bg: 'bg-1',
        img: img4
    },
    {
        bg: 'bg-2',
        img: img5
    },
    {
        bg: 'bg-1',
        img: img6
    },
    {
        bg: 'bg-2',
        img: img7
    },
    {
        bg: 'bg-1',
        img: img8
    },
    {
        bg: 'bg-2',
        img: img9
    },
    {
        bg: 'bg-1',
        img: img10
    },
    {
        bg: 'bg-2',
        img: img11
    },
    {
        bg: 'bg-1',
        img: img12
    },
    {
        bg: 'bg-2',
        img: img13
    },
    {
        bg: 'bg-1',
        img: img14
    },
    {
        bg: 'bg-2',
        img: img15
    },
    {
        bg: 'bg-1',
        img: img16
    },
    {
        bg: 'bg-2',
        img: img17
    },
    {
        bg: 'bg-1',
        img: img18
    },
  
]

export default dataProductos;