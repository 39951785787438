import React, { useState } from "react";
import logo from "../assets/fake-data/logo";

const Slider = () => {
  const [datatext] = useState({
    title: "@fricap",
    desc: "Fricap Helados - Envíos a domicilio ",
    desc2: "Lunes a Sabados de 9:30h a 20h - Dom 10h a 14h",
    desc3: "Lunes a Sabados de 9:30h a 20h - Dom 10h a 14h",
  });

  return (
    <section className="linktree">
      <div className="container">
        <div className="row">
          <div className="link-contenedor">
            <div className="link-logo">
              <img src={logo} alt="ESCALE" data-aos="fade-down" />
            </div>
            <div className="block-text pt-24">
              <h2 className="title mb-26" data-aos="fade-up">
                {datatext.title}{" "}
              </h2>
              <p className="desc mb-43" data-aos="fade-up">
                {datatext.desc}{" "}
              </p>
              <ul className="list-bottom">
                <li>
                  <a
                    href="https://fricap.com.ar/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-action3 style-3"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <i class="fas fa-globe"></i> Web Site
                  </a>
                </li>
                <li>
                  <a
                    href="https://walink.co/fa8004"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-action3 style-3"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <i className="fab fa-whatsapp link-icon"></i>
                    Whatsapp - San Miguel
                  </a>
                </li>
                <li>
                  <a
                    href="https://walink.co/0b231c"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-action3 style-3"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <i className="fab fa-whatsapp link-icon"></i>
                    Whatsapp - Yerba Buena
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pedidosya.com.ar/restaurantes/san-miguel-de-tucuman/heladeria-fricap-5c31c40d-1fe8-4512-8735-a9a5a9a14423-menu?search=fricap"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-action3 style-3"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <i class="fas fa-shopping-cart"></i> Pedidos Ya! - San
                    Miguel
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pedidosya.com.ar/restaurantes/yerba-buena/fricap-helados-y-achilata-189985a8-72f7-4eb3-a054-40edda7af6e8-menu?search=fricap"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-action3 style-3"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <i class="fas fa-shopping-cart"></i> Pedidos Ya! - Yerba
                    Buena
                  </a>
                </li>
              </ul>{" "}
              <div className="footer__body">
                <ul className="link-social">
                  <li data-aos="fade-up" data-aos-duration="1200">
                    <a
                      href="https://www.instagram.com/fricaphelados/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1200">
                    <a
                      href="https://www.facebook.com/FricapHelados"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                </ul>
                <p className="fs-16 link-escale">
                  Diseñado por <n />
                  <a
                    href="https://escalewebs.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ESCALE
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
