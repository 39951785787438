const item = {
  item1: require("../images/items/item-01.png"),
  item5: require("../images/items/item-05.png"),
  item8: require("../images/items/item-08.png"),
  item9: require("../images/items/item-09.png"),
  item10: require("../images/items/item-10.png"),
  item11: require("../images/items/item-11.png"),
  item13: require("../images/items/item-13.png"),
  item21: require("../images/items/item-21.png"),
  item23: require("../images/items/item-23.png"),
  item24: require("../images/items/item-24.png"),
  item25: require("../images/items/item-25.png"),
  item26: require("../images/items/item-26.png"),
  item27: require("../images/items/item-08.png"),
  item28: require("../images/items/item-09.png"),
  item29: require("../images/items/item-10.png"),
  item30: require("../images/items/item-30.png"),
  item31: require("../images/items/item-31.png"),
  item32: require("../images/items/item-32.png"),
  item33: require("../images/items/item-33.png"),
  item34: require("../images/items/item-34.png"),
  item35: require("../images/items/item-35.png"),
  item36: require("../images/items/item-36.png"),
  item37: require("../images/items/item-37.png"),
  item38: require("../images/items/item-38.png"),
  item39: require("../images/items/item-39.png"),
  item40: require("../images/items/item-40.png"),
  item41: require("../images/items/item-41.png"),
  item42: require("../images/items/item-42.png"),
  item43: require("../images/items/item-43.png"),

  technology: require("../images/items/item-10.png"),
};

export default item;
