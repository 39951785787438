import React from "react";
import webTotalCel from "../../assets/images/pages/webTotalCel3.png";

const Truck = () => {
  return (
    <div className="service" id="truck">
      <section className="tf-section services">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="block-text pt-12">
                <h5
                  className="sub-title mb-10"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Consentí a tus invitados
                </h5>
                <h3
                  className="title mb-37"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Food Truck para Eventos
                </h3>
                <ul data-aos="fade-up" data-aos-duration="1000">
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">Traslado del Food al evento</p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Personal para servir a los invitados
                    </p>
                  </li>
                  <h5
                    className="title mb-8"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Te lo cotizamos en minutos, mandanos un mensaje.
                  </h5>
                  <a
                    href="https://walink.co/fa8004"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-action style-2"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    Pedir Cotización
                  </a>
                  <li className="mt-27">
                    <i class="fa fa-truck" aria-hidden="true"></i>
                    <p className="fs-18">
                      Buscá nuestro Food Truck en plazas, ferias y eventos
                      masivos.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div
                className="group-image"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <img
                  className="image-single"
                  src={webTotalCel}
                  alt="WebMovil"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Truck;
