import React from "react";
import item from "../../assets/fake-data/item";

const Portfolio2 = (props) => {
  return (
    <section className="tf-section products" id="productos">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h5
                className="sub-title mb-10"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Refrescantes y Deliciosos
              </h5>
              <h3
                className="title mb-28"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Tenemos una gran variedad
                <br /> de productos
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-products">
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Balde de Achilata</h4>
          <img className="img-product" src={item.item10} alt="compra Movil" />
          <p className="vol-product1">1 Litro</p>
          <p className="vol-product2">3 Litros</p>
          <p className="vol-product3">5 Litros</p>
          <p className="vol-product4">10 Litros</p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Helado de Crema</h4>
          <img className="img-product" src={item.item1} alt="compra Movil" />
          <p className="vol-product1">3 Litros</p>
          <p className="vol-product2">5 Litros</p>
          <p className="vol-product3">10 Litros</p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Envases Térmicos</h4>
          <img className="img-product" src={item.item23} alt="compra Movil" />
          <p className="vol-product1">1 Litro</p>
          <p className="vol-product2">3 Litros</p>
          <p className="vol-product3">5 Litros</p>
          <p className="vol-product4">10 Litros</p>
        </div>
      </div>
      <section id="palitos">
        <div className="block-text center">
          <h3
            className="title mb-28"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Palitos Helados
          </h3>
        </div>
        <div className="container-products">
          <div className="single-product" data-aos="fade-up">
            <h4 className="title-product">Palitos de Crema</h4>
            <img className="img-product" src={item.item21} alt="compra Movil" />
            <p className="vol-product3">
              Descuento <br />
              x25 Unid.
            </p>
          </div>
          <div className="single-product" data-aos="fade-up">
            <h4 className="title-product">Palito Bombón</h4>
            <img className="img-product" src={item.item11} alt="compra Movil" />
            <p className="vol-product3">
              Descuento <br />
              x25 Unid.
            </p>
          </div>
          <div className="single-product" data-aos="fade-up">
            <h4 className="title-product">Palitos de Agua</h4>
            <img className="img-product" src={item.item5} alt="compra Movil" />
            <p className="vol-product3">
              Descuento <br />
              x25 Unid. <br />
              x50 Unid.
            </p>
          </div>
          <div className="single-product" data-aos="fade-up">
            <h4 className="title-product">Paletas Rellenas</h4>
            <img className="img-product" src={item.item13} alt="compra Movil" />
            <p className="vol-product3">
              Descuento <br />
              x2 Unid.
            </p>
          </div>
          <div className="single-product" data-aos="fade-up">
            <h4 className="title-product">Palitones de Achilata</h4>
            <img className="img-product" src={item.item42} alt="compra Movil" />
            <p className="vol-product3">
              Descuento <br />
              x25 Unid.
            </p>
          </div>
          <div className="single-product" data-aos="fade-up">
            <h4 className="title-product">Palitos MIV</h4>
            <img className="img-product" src={item.item43} alt="compra Movil" />
            <p className="vol-product3">
              Descuento <br />
              x25 Unid.
            </p>
          </div>
        </div>
      </section>
      <div className="block-text center">
        <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">
          Cucuruchos
        </h3>
      </div>
      <div className="container-products">
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Cono Dulce N4</h4>
          <img className="img-product" src={item.item24} alt="compra Movil" />

          <p className="vol-product3">
            Descuento
            <br />
            x10 Unid.
            <br />
            x160 Unid.
          </p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Cucuruchon</h4>
          <img className="img-product" src={item.item25} alt="compra Movil" />
          <p className="vol-product3">
            Descuento
            <br />
            x10 Unid.
            <br />
            x250 Unid.
          </p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Cono Doble</h4>
          <img className="img-product" src={item.item26} alt="compra Movil" />
          <p className="vol-product3">
            Descuento
            <br />
            x10 Unid.
            <br />
            x120 Unid.
          </p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Cono N50</h4>
          <img className="img-product" src={item.item30} alt="compra Movil" />
          <p className="vol-product3">
            Descuento
            <br />
            x50 Unid.
            <br />
            x320 Unid.
          </p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Capelina</h4>
          <img className="img-product" src={item.item31} alt="compra Movil" />
          <p className="vol-product3">
            Descuento
            <br />
            x20 Unid.
            <br />
            x180 Unid.
          </p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Cubanitos</h4>
          <img className="img-product" src={item.item32} alt="compra Movil" />
          <p className="vol-product3">
            Descuento
            <br />
            x10 Unid.
          </p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Vaso N9</h4>
          <img className="img-product" src={item.item33} alt="compra Movil" />
          <p className="vol-product3">
            Descuento
            <br />
            x10 Unid.
          </p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Obleas</h4>
          <img className="img-product" src={item.item34} alt="compra Movil" />
          <p className="vol-product3">
            Descuento
            <br />
            x10 Unid.
          </p>
        </div>
      </div>
      <div className="block-text center">
        <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">
          Especiales
        </h3>
      </div>
      <div className="container-products">
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Tortas Heladas</h4>
          <img
            className="img-product extra-product"
            src={item.item9}
            alt="compra Movil"
          />
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Bombón Escoces</h4>
          <img className="img-product" src={item.item35} alt="compra Movil" />
          <p className="vol-product3">
            Descuento
            <br />
            x6 Unid.
          </p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Tacitas</h4>
          <img className="img-product" src={item.item36} alt="compra Movil" />
          <p className="vol-product3">
            Descuento
            <br />
            x10 Unid.
          </p>
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Sandwichitos</h4>
          <img className="img-product" src={item.item37} alt="compra Movil" />
          <p className="vol-product3">
            Descuento
            <br />
            x10 Unid.
          </p>
        </div>
      </div>
      <div className="block-text center">
        <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">
          Varios
        </h3>
      </div>
      <div className="container-products">
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Cucharitas</h4>
          <img
            className="img-product extra-product"
            src={item.item38}
            alt="compra Movil"
          />
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Mediabola</h4>
          <img
            className="img-product extra-product"
            src={item.item39}
            alt="compra Movil"
          />
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Salsas</h4>
          <img
            className="img-product extra-product"
            src={item.item40}
            alt="compra Movil"
          />
        </div>
        <div className="single-product" data-aos="fade-up">
          <h4 className="title-product">Espatulas</h4>
          <img
            className="img-product extra-product"
            src={item.item41}
            alt="compra Movil"
          />
        </div>
      </div>
    </section>
  );
};

export default Portfolio2;
